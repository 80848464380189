import React from 'react';
import './UpcomingSets.css'; // Optional: You can add section-specific styling here

const UpcomingSets = () => {
  return (
    <div className="sets">
      <h2>Upcoming Sets</h2>
      <ul>
        <li>Friday, Oct 4th - Apollo's Restaurant</li>
        <li>Saturday, Oct 5th - Seattle</li>
        <li>Saturday, Oct 26th - Halloween Vancouver Sunroof</li>
      </ul>
    </div>
  );
};

export default UpcomingSets;
