import React, { useState } from 'react';
import './Donations.css'; // Importing specific CSS for this component

const Donations = () => {
  const [activeTab, setActiveTab] = useState('Venmo');

  const renderContent = () => {
    switch (activeTab) {
      case 'Venmo':
        return <p>Donate via Venmo: <b>@davincimusik</b></p>;
      case 'Etransfer':
        return <p>Donate via etransfer: <b>contact@davincimusik.info</b></p>;
      case 'LightningNetwork':
        return (
          <div>
            <p>Scan the QR code to donate via the Lightning Network:</p>
            <img className="qr-code" src="https://cperez-useast1bucket.s3.amazonaws.com/ln.jpeg" alt="Lightning Network QR Code" />
          </div>
        );
      case 'BTC':
        return (
          <div>
            <p>BTC address:</p>
            <b>bc1q4v829lfsl9ezgr7za5jjz0rzralunhafuvguxv</b>
          </div>
        );
      case 'ETH':
        return (
        <div>
            <p>ETH address:</p>
           <b>0x1f917C26a0d920a46Ce39715dEC3436F8f04860c</b>
        </div>
        );
      default:
        return null;
    }
  };

  return (
    <div id="donation">
      <h2>Donations</h2>
      <div className="donation-tabs">
        <button className={activeTab === 'Venmo' ? 'active' : ''} onClick={() => setActiveTab('Venmo')}>Venmo</button>
        <button className={activeTab === 'Etransfer' ? 'active' : ''} onClick={() => setActiveTab('Etransfer')}>Etransfer</button>
        <button className={activeTab === 'LightningNetwork' ? 'active' : ''} onClick={() => setActiveTab('LightningNetwork')}>Lightning Network</button>
        <button className={activeTab === 'BTC' ? 'active' : ''} onClick={() => setActiveTab('BTC')}>Bitcoin</button>
        <button className={activeTab === 'ETH' ? 'active' : ''} onClick={() => setActiveTab('ETH')}>Ethereum</button>
      </div>
      <div className="tabcontent">{renderContent()}</div>
    </div>
  );
};

export default Donations;
