import React from 'react';
import Logo from '../images/logo_orange.svg';
import './Title.css'; // Ensure you import the updated CSS


const Title = () => {
  return (
    <div className="davinci-title-container">
      {/* Logo and Title Side by Side */}
      <img src={Logo} alt="Davinci Musik Logo" className="davinci-logo" />
      <h1 className="davinci-title">Davinci Musik</h1>
    </div>
  );
};

export default Title;
