import React from 'react';
import instagramIcon from '../images/instagram.svg';
import emailIcon from '../images/mail.svg';
import soundcloudIcon from '../images/soundcloud.svg';

import './Contact.css'; // Optional: You can add section-specific styling here


const Contact = () => {
  return (
    <div className="info-section">
      <h2>Contact Us</h2>
      <p className="ourlinks">
        <a href="mailto:contact@davincimusik.info" target="_blank" rel="noopener noreferrer">
          <img className="icon" src={emailIcon} alt="Email" />
          Email: <b>contact@davincimusik.info</b>
        </a>
      </p>

      <p className="ourlinks">
        <a href="https://www.instagram.com/davinci.musik/" target="_blank" rel="noopener noreferrer">
          <img className="icon" src={instagramIcon} alt="Instagram" />
          Instagram: <b>davinci.musik</b>
        </a>
      </p>

      <p className="ourlinks">
        <a href="https://soundcloud.com/davincimusik/" target="_blank" rel="noopener noreferrer">
          <img className="icon" src={soundcloudIcon} alt="Instagram" />
          SoundCloud: <b>davinci musik</b>
        </a>
      </p>
    </div>
  );
};

export default Contact;
