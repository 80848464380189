import React from 'react';
import './App.css';
import Contact from './components/Contact';
import Donations from './components/Donations';
import Title from './components/Title';
import UpcomingSets from './components/UpcomingSets';

function App() {
  return (
    <div className="App">
      <Title />
      <main>
        <Contact />       {/* Contact Us Section */}
        <UpcomingSets />     {/* Upcoming Sets Section */}
        <Donations />        {/* Donations Section */}
      </main>

      <footer>
        <p>© 2024 Davinci Musik. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default App;
